import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter)
export default new VueRouter({
    mode: 'history',
    base: '/',
    routes: [{
        path: '/',
        component: () => import('@/views/home.vue')
    }
    ]
}) 