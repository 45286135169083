<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
}
</style>
